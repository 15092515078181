export const modalState = {
  loading: true,

  template: '',

  data: {},

  updateContent(newTemplate, newData, isLoading = false) {
    this.template = newTemplate;
    this.loading = isLoading;
    this.data = newData;
  },
};

export const dynamicContentState = {
  loadedItems: [],
  hasItem(id) {
    return this.loadedItems.find((item) => item.id === id);
  },

  addItem(id, template = null) {
    this.loadedItems.push({ id, card_template: template });
  },

  addItems(items = []) {
    items.forEach((item) => {
      this.loadedItems.push(item);
    });
  },

  async updateItems(newItems = []) {
    const resultMap = new Map(newItems.map((item) => [item.id, item]));

    const itemsToRemove = this.loadedItems.reduce((acc, item) => {
      if (!resultMap.has(item.id)) {
        acc.push(item.id);
      }
      return acc;
    }, []);

    this.removeItems(itemsToRemove);

    const itemsToAdd = newItems.filter((item) => !this.hasItem(item.id));

    this.addItems(itemsToAdd);
  },

  removeItem(id = null) {
    this.loadedItems.splice(id, 1);
  },

  removeItems(ids = []) {
    ids.forEach((id) => {
      const index = this.loadedItems.findIndex((item) => item.id === id);
      this.loadedItems.splice(index, 1);
    });
  },

  clearItems() {
    this.loadedItems = [];
  },
};
