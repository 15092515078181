import animateScrollTo from 'animated-scroll-to';

function init(selector = '.smooth-scroll') {
  let scrollOffset = 0;

  const mainNavEl = document.querySelector('.site-header');

  scrollOffset = parseInt(mainNavEl ? mainNavEl.offsetHeight : 0);

  const smoothScrollElements = document.querySelectorAll(selector);

  if (smoothScrollElements.length === 0) {
    return;
  }

  smoothScrollElements.forEach((element) => {
    element.addEventListener('click', (event) => {
      event.preventDefault();

      const targetId = element.getAttribute('href');

      const url = new URL(targetId, window.location.href);
      const curUrl = new URL(window.location.href);

      if (
        `${url.origin}${url.pathname}` !== `${curUrl.origin}${curUrl.pathname}`
      ) {
        window.location.href = url;
        return;
      }

      const targetPosition = document.querySelector(url.hash);

      if (
        targetPosition &&
        `${url.origin}${url.pathname}` === `${curUrl.origin}${curUrl.pathname}`
      ) {
        animateScrollTo(targetPosition.offsetTop, {
          speed: 900,
          easing: (t) => {
            return t < 0.5
              ? 16 * t * t * t * t * t
              : 1 + 16 * --t * t * t * t * t;
          },
          verticalOffset: -scrollOffset + 1,
          maxDuration: 2000,
        });
      }
    });
  });
}

export default init;
