import AsyncAlpine from 'async-alpine';
import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import intersect from '@alpinejs/intersect';
import collapse from '@alpinejs/collapse';

import domReady from '@roots/sage/client/dom-ready';
import offCanvasElement from './components/off-canvas-element';
import smoothScroll from './components/smooth-scroll';

import { modalState, dynamicContentState } from './globalState';

window.Alpine = Alpine;
Alpine.plugin(focus);
Alpine.plugin(intersect);
Alpine.plugin(collapse);
Alpine.plugin(AsyncAlpine);

Alpine.asyncData(
  'modalController',
  () => import('./components/modal/index.js'),
);
Alpine.asyncData(
  'speakerModal',
  () => import('./components/modal/speakerModal.js'),
);
Alpine.asyncData(
  'queryPosts',
  () => import('./components/alpine/queryPosts.js'),
);
Alpine.asyncData(
  'cventFetch',
  () => import('./components/alpine/cventFetch.js'),
);
Alpine.asyncData(
  'cventAgendaFetch',
  () => import('./components/alpine/agendaFetch.js'),
);
Alpine.asyncData('linkify', () => import('./components/alpine/linkify.js'));

Alpine.store('speakerModal', modalState);
Alpine.store('sessionModal', modalState);
Alpine.store('speakersState', dynamicContentState);
Alpine.store('sessionsState', dynamicContentState);
Alpine.store('agendaState', {
  ...dynamicContentState,
  loadedItemsGroupedByDate: [],
});
Alpine.start();

/**
 * Application entrypoint
 */
domReady(async () => {
  smoothScroll('.smooth-scroll a');

  if (document.querySelector('.wp-block-slider .swiper')) {
    import(/* webpackChunkName: "slider" */ './components/slider/index.js')
      .then((module) => module.default('.wp-block-slider .swiper'))
      .catch((error) => {
        throw new Error(
          'An error occurred while loading the slider component \n' +
            error.message,
        );
      });
  }

  if (document.querySelector('.splide')) {
    import(/* webpackChunkName: "splide" */ './components/splide/index.js')
      .then((module) => module.default('.splide'))
      .catch((error) => {
        throw new Error(
          'An error occurred while loading the marquee component \n' +
            error.message,
        );
      });
  }
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
